/******** Theme Scripts *********/


const h1Elements = document.querySelectorAll('h1');
h1Elements.forEach((h1, index) => {
  if (index === 0) {
    return;
  }
  const div = document.createElement('div');
  div.className = 'display-large';
  div.innerHTML = h1.innerHTML;
  div.style.cssText = h1.style.cssText;
  div.classList.add(...h1.classList);
  h1.replaceWith(div);
});

// Get text color based on contrast
/**
 * Calculates the contrast color (black or white) based on the given hex color.
 *
 * @param {string} hexColor - The hex color code.
 * @returns {string} The contrast color ('black' or 'white').
 */
const getContrastYIQ = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return yiq >= 128 ? '#2F3587' : 'white';
};

// Convert RGB to hex color
const rgbToHex = (rgb) => {
  const [r, g, b] = rgb.match(/\d+/g);
  const hex = ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  return `#${hex}`;
};

/**
 * Sets the text color based on the background color of the specified element.
 *
 * @param {HTMLElement} unique_id - The unique identifier of the element.
 * @returns {void}
 */
/**
 * Sets the text color of all elements inside the given app based on its background color.
 * 
 * @param {string} unique_id - The unique identifier of the app.
 * @returns {void}
 */
const setTextColorBasedOnBackground = (unique_id) => {
  // Set the color based on background if there is
  const app = unique_id;
  const color = app.style.backgroundColor;
  if (!color) {
    return;
  }
  const hexColor = rgbToHex(color);

  const textColor = getContrastYIQ(hexColor);
  // set all elements color inside the app
  const elements = app.querySelectorAll('*');
  // Array of exempt elements
  const exempt = ['button', 'a', 'input', 'select', 'textarea', 'h6'];
  elements.forEach(element => {
    if (exempt.includes(element.tagName.toLowerCase())) {
      return;
    }
    element.style.color = textColor;
  });
}

const columnModules = document.querySelectorAll('.column-module');
columnModules.forEach(columnModule => {
  setTextColorBasedOnBackground(columnModule);
});


/**
 * Represents a collection of accordion items.
 * @type {NodeList}
 */
const accordionItems = document.querySelectorAll('.accordion-item');
accordionItems.forEach(item => {
    item.addEventListener('click', function () {
        // Toggle active class on the clicked item
        item.classList.toggle('active');
        // Close other items
        accordionItems.forEach(otherItem => {
            if (otherItem !== item) {
                otherItem.classList.remove('active');
            }
        });
    });
});


/**
//  * Represents a collection of tabbed sections.
//  * @type {NodeList}
//  */
const tabbedFAQ = document.querySelectorAll('.tabbed-section');
// check if tabbedFAQ is in preview mode
tabbedFAQ.forEach(tabbed => {
  
  const tabButtons = tabbed.querySelectorAll('.tab-btn');
  const tabContents = tabbed.querySelectorAll('.tab');
  // check if tabbed has .is-preview class
  const isPreview = tabbed.classList.contains('is-preview');


  tabButtons.forEach((button, index) => {
    button.addEventListener('click', () => {
      tabButtons.forEach((btn, i) => {
        btn.classList.remove('active');
        tabContents[i].classList.remove('active');
      });
      button.classList.add('active');
      tabContents[index].classList.add('active');
    });
  });
  // Set the first tab active
  if (tabButtons.length > 0) {
    if (isPreview ) {
      return;
    }
    tabButtons[0].click();
  }
});



/**
 * Represents the hero banner element.
 * @type {HTMLElement}
 */
const heroBanner = document.querySelector('#hero-banner');
if (heroBanner) {
  // load the countdown.js file
  import('./countdown');
}

const swiperContainers = document.querySelectorAll('.swiper-container');
if (swiperContainers.length > 0) {
  // load the swiper-config.js file
  import('./swiper-init');
}



/**
 * Changes the color of the navigation bar icons on scroll.
 * 
 * @param {NodeList} elements - The elements to check for scroll position.
 * @returns {void}
 */
// Function to change the nav-bar icons color
function changeNavBarIconsColorOnScroll(elements) {
  const navBar = document.querySelector('.nav-bar');
  const headerLogo = document.querySelector('#main-header-logo');
  if (!navBar) {
    return;
  }
  const navBarHeight = navBar.offsetHeight;

  // Determine if the nav-bar should have the white-icons class
  let shouldAddWhiteIcons = false;

  elements.forEach(element => {
    const elementTop = element.getBoundingClientRect().top + window.scrollY;
    const elementBottom = elementTop + element.offsetHeight;

    if (window.scrollY >= elementTop - navBarHeight && window.scrollY < elementBottom - navBarHeight) {
      shouldAddWhiteIcons = true;
    }
  });

  if (shouldAddWhiteIcons) {
    navBar.classList.add('white-icons');
    headerLogo.src = headerLogo.src.replace('logo_blue', 'logo_white');
  } else {
    navBar.classList.remove('white-icons');
    headerLogo.src = headerLogo.src.replace('logo_white', 'logo_blue');
  }
}

// // Initialize and attach the scroll event listener
// window.addEventListener('scroll', () => {
//   // Gather all elements to watch
//   const columnModules = document.querySelectorAll('.column-module.blue-class');
//   // heroBanner defined above

//   const elementsToWatch = heroBanner ? [heroBanner] : [];
//   if (heroBanner) {
//     elementsToWatch.push(heroBanner);
//   }
//   elementsToWatch.push(...columnModules);

//   changeNavBarIconsColorOnScroll(elementsToWatch);
// }, 100);

// // Trigger an initial scroll event to apply the initial state
// window.dispatchEvent(new Event('scroll'));



/**
 * Partner Grid
 * @type {NodeList}
 */
import Packery from 'packery';

window.addEventListener('load', function() {
  const partnerGrids = document.querySelectorAll('.partners-grid');
  partnerGrids.forEach((element) => {
    /**
     * Initializes a new instance of Packery.
     *
     * @constructor
     * @param {Element} element - The container element for the Packery grid.
     * @param {Object} options - The options for configuring the Packery grid.
     * @param {string} options.itemSelector - The selector for the grid items.
     * @param {string} options.layoutMode - The layout mode for the grid.
     * @param {string} options.columnWidth - The selector for the column width element.
     * @param {boolean} options.percentPosition - Whether to use percent positioning for grid items.
     * @param {number} options.gutter - The size of the gutter between grid items.
     */
    const packery = new Packery(element, {
      // options
      itemSelector: '.grid-item',
      layoutMode: 'masonry',
      columnWidth: '.grid-item',
      percentPosition: true,
      gutter: 40,
    });

  });
});


function header_logo_load() {
  const headerLogo = document.querySelector('#main-header-logo');
  if (!headerLogo) {
    return;
  }
  // after page is fully loaded add loaded class
  headerLogo.classList.add('loaded');
}
window.addEventListener('load', header_logo_load);