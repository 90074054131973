import "./bootstrap-config";
import "./font-awesome-config";
import "../styles/main.scss";
import "../fonts/grotesque/GalanoGrotesque.css";

// Uncomment to add Animate on Scroll library
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1000,
  once: false,
  mirror: true,
});

// Uncomment to add Swiper carousel library
import "./swiper-config";


// Uncomment to add Fancybox light box library
// import Fancybox from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox/fancybox.css";
// Fancybox.bind("[data-fancybox]", {
// });

import "./theme";